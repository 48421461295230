/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import Link from 'gatsby-link'
import { css, jsx } from '@emotion/core'

// import { routes } from '../utils'
import {
  Page,
  H1,
  Hr,
  // GatsbyLink,
  Box,
  Flex,
  // FeatherIcon,
  Text,
} from '../elements'
import { SEO, HeadingStaticPage } from '../components'
import { breakLongStrings, addInlineLinks } from '../utils'

const GamesMarketPage = () => (
  <Page>
    {({ t }) => {
      const str = breakLongStrings({ string: t('gamesMarketText'), tag: 'p' })
      const strWithLinks = addInlineLinks(str)

      return (
        <React.Fragment>
          <SEO
            {...{
              url: `/games-market/`,
              title: t('gamesMarket'),
              // description: true,
            }}
          />
          <HeadingStaticPage
            {...{
              // icon: 'map-pin',
              bg: 'gamesMarket',
              color: 'textAlt',
              title: t('gamesMarket'),
            }}
          />
          <Box.container>
            <Text
              {...{
                as: 'p',
                color: 'text',
                className: 'break show-links',

                dangerouslySetInnerHTML: { __html: strWithLinks },
              }}
            />
          </Box.container>
        </React.Fragment>
      )
    }}
  </Page>
)

export default GamesMarketPage
